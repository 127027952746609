<template>
  <el-row>
    <el-col
      :xs="xsConfig"
      :sm="smConfig"
      :md="mdConfig"
      :lg="lgConfig"
      :xl="xlConfig"
    >
      <slot /> </el-col
  ></el-row>
</template>

<script>
export default {
  props: {
    xs: {
      required: false,
      type: Number,
      default: 22,
      validator: val => val % 2 === 0
    },
    sm: {
      required: false,
      type: Number,
      default: 20,
      validator: val => val % 2 === 0
    },
    md: {
      required: false,
      type: Number,
      default: 16,
      validator: val => val % 2 === 0
    },
    lg: {
      required: false,
      type: Number,
      default: 16,
      validator: val => val % 2 === 0
    },
    xl: {
      required: false,
      type: Number,
      default: 16,
      validator: val => val % 2 === 0
    }
  },
  data() {
    return {
      totalCols: 24
    };
  },
  computed: {
    offset() {
      return span => (this.totalCols - span) / 2;
    },
    xsConfig() {
      return {
        span: this.xs,
        offset: this.offset(this.xs)
      };
    },
    smConfig() {
      return {
        span: this.sm,
        offset: this.offset(this.sm)
      };
    },
    mdConfig() {
      return {
        span: this.md,
        offset: this.offset(this.md)
      };
    },
    lgConfig() {
      return {
        span: this.lg,
        offset: this.offset(this.lg)
      };
    },
    xlConfig() {
      return {
        span: this.xl,
        offset: this.offset(this.xl)
      };
    }
  }
};
</script>

<style scoped></style>
