<script>
import { mapState } from "vuex";
import localStorageSession from "@/utils/localStorageManager";
import { checkStatusOfTaskImportRequest } from "@/api/tasks";
import _ from "lodash";

const COMPLETED_STATUS = "completed";
const ERROR_STATUS = "error";

export default {
  name: "TaskImportExportRequest",
  data() {
    return {
      loadingText: "",
      socketConnectionEstablished: false,
      taskImportExportStatusCheckInterval: null
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      token: state => state.token
    }),

    getLoadingText() {
      return __(this.loadingText) + (this.loadingText ? "..." : "");
    },

    getChannelToListenToRequestUpdates() {
      return (taskId, user, requestId) => {
        return (
          "account." +
          this.selectedAccountId +
          ".task." +
          taskId +
          ".user." +
          user.user_id +
          ".request." +
          requestId
        );
      };
    }
  },

  methods: {
    listenForTaskImportExportUpdates(
      response,
      taskId,
      onCompletionCallback,
      onErrorCallback,
      statusIntervalCheckCallback = null
    ) {
      let { request_id: requestId } = response;

      if (requestId && taskId) {
        this.establishConnectionToTaskImportExportChannel(
          taskId,
          requestId,
          onCompletionCallback,
          onErrorCallback
        );
        this.createTaskImportExportStatusCheckInterval(
          taskId,
          requestId,
          onCompletionCallback,
          onErrorCallback,
          statusIntervalCheckCallback
        );
      } else {
        console.error(
          "task import export request id or task id missing from response",
          { response, taskId }
        );
      }
    },

    onEvent({ last_success_state }) {
      this.socketConnectionEstablished = true;
      this.loadingText = last_success_state;
      if (this.taskImportExportStatusCheckInterval) {
        this.clearTaskImportExportStatusCheckInterval();
      }
    },

    onCompletionEvent(taskId, event, onCompletionCallback) {
      onCompletionCallback(taskId, event);
      this.socketConnectionEstablished = false;
    },

    onErrorEvent(taskId, event, onErrorCallback) {
      onErrorCallback(taskId, event);
      this.socketConnectionEstablished = false;
    },

    handleEventForStatus(taskId, event, onCompletionCallback, onErrorCallback) {
      let { status } = event;
      if (status === COMPLETED_STATUS) {
        this.onCompletionEvent(taskId, event, onCompletionCallback);
        return;
      }

      if (status === ERROR_STATUS) {
        this.onErrorEvent(taskId, event, onErrorCallback);
      }
    },

    establishConnectionToTaskImportExportChannel(
      taskId,
      requestId,
      onCompletionCallback,
      onErrorCallback
    ) {
      if (this.token && this.$echo && this.$echo.connector.socket.connected) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + localStorageSession.get("studio7-user-token");

        this.$echo
          .private(
            this.getChannelToListenToRequestUpdates(
              taskId,
              this.$auth.user(),
              requestId
            )
          )
          .listen("TaskImportExportEvent", event => {
            console.info({ TaskImportExportEvent: event });
            this.onEvent(event);
            this.handleEventForStatus(
              taskId,
              event,
              onCompletionCallback,
              onErrorCallback
            );
          });
      }
    },

    async statusCheckFn(
      taskId,
      requestId,
      onCompletionCallback,
      onErrorCallback,
      statusIntervalCheckCallback
    ) {
      let response = await checkStatusOfTaskImportRequest({
        requestId
      });

      let event = _.get(response, "data.data", {});
      this.loadingText = event.last_success_state || "";

      if (
        this.taskImportExportStatusCheckInterval &&
        ((statusIntervalCheckCallback && statusIntervalCheckCallback(event)) ||
          (!statusIntervalCheckCallback &&
            this.isTaskImportExportStatusCheckIntervalClearable(event)))
      ) {
        this.clearTaskImportExportStatusCheckInterval();
        this.handleEventForStatus(
          taskId,
          event,
          onCompletionCallback,
          onErrorCallback
        );
      }
    },

    stopListeningToTaskImportExportChannel(taskId, requestId) {
      if (taskId && requestId) {
        this.$echo.leave(
          this.getChannelToListenToRequestUpdates(
            taskId,
            this.$auth.user(),
            requestId
          )
        );
        this.socketConnectionEstablished = false;
        this.loadingText = "";
      }
    },

    isTaskImportExportStatusCheckIntervalClearable(event) {
      let { status } = event;
      return (
        this.socketConnectionEstablished ||
        status === COMPLETED_STATUS ||
        status === ERROR_STATUS
      );
    },

    createTaskImportExportStatusCheckInterval(
      taskId,
      requestId,
      onCompletionCallback,
      onErrorCallback,
      statusIntervalCheckCallback
    ) {
      this.taskImportExportStatusCheckInterval = setInterval(
        () =>
          this.statusCheckFn(
            taskId,
            requestId,
            onCompletionCallback,
            onErrorCallback,
            statusIntervalCheckCallback
          ),
        1000
      );
    },

    clearTaskImportExportStatusCheckInterval() {
      if (this.taskImportExportStatusCheckInterval) {
        clearInterval(this.taskImportExportStatusCheckInterval);
        this.taskImportExportStatusCheckInterval = null;
      }
    }
  }
};
</script>
