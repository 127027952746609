<template>
  <div style="display: flex;align-items: center">
    <el-popover
      v-model="showImageGallery"
      placement="bottom-start"
      :width="540"
      popper-class="imageLibrary"
    >
      <div
        style="display: flex; justify-content: center; color: #A0A8B5; font-size: 13px"
      >
        {{ __("Change Image") }}
      </div>
      <div style="display: flex ;flex-wrap: wrap">
        <div
          class="imageThumb"
          v-for="n in 24"
          :key="`task_image_${25 - n}`"
          @click="$emit('image-selected', `task_image_${25 - n}.svg`)"
        >
          <img
            :src="
              require('@/assets/images/taskImages/task_image_' +
                (25 - n) +
                '.svg')
            "
            :alt="`task_image_${25 - n}`"
            width="80px"
            height="80px"
          />
        </div>
      </div>
      <div
        style="position:relative; display: flex; align-items: center"
        class="taskImage"
        slot="reference"
      >
        <img :src="getTaskImage" alt="" />
        <el-button icon="el-icon-edit" class="editTaskImageIcon"></el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "TaskImageLibrary",
  props: {
    imagePathProp: {
      required: true,
      type: String
    }
  },
  computed: {
    getTaskImage() {
      if (this.imagePath) {
        return require("@/assets/images/taskImages/" + this.imagePath);
      }
      return require("@/assets/images/taskImages/task_image_placeholder.svg");
    }
  },
  data() {
    return {
      showImageGallery: false,
      imagePath: ""
    };
  },
  watch: {
    imagePathProp: {
      immediate: true,
      handler: function(val) {
        this.imagePath = val;
      }
    }
  }
};
</script>
<style lang="scss">
.imageLibrary {
  display: flex;
  flex-direction: column;
}

.imageThumb {
  display: flex;
  padding: 5px;

  &:hover {
    background: #f5f5f5;
  }
}
</style>
<style scoped lang="scss">
.taskImage {
  background: #f5f5f5;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    .editTaskImageIcon {
      opacity: 1;
    }
  }
}

.editTaskImageIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  opacity: 0;
  border: none;
  padding: 0;
  background: transparent;
}
</style>
