<template>
  <div>
    <el-tag
      :key="tag"
      v-for="tag in getTagsArray"
      closable
      :disable-transitions="false"
      @close="handleDeleteTag(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible && !autocomplete"
      v-model="inputValue"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    ></el-input>
    <el-autocomplete
      class="input-new-tag"
      v-else-if="inputVisible && autocomplete"
      :fetch-suggestions="querySearch"
      v-model="inputValue"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
      @select="handleInputConfirm"
    >
      <i
        class="el-icon-close el-input__icon"
        slot="suffix"
        @click="handleTagInputClose"
      >
      </i>
    </el-autocomplete>
    <el-button
      v-else
      class="button-new-tag"
      size="small"
      @click="showInput"
      id="add_tag_btn"
      >+ {{ __("New Tag") }}
    </el-button>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "TagsInput",
  data() {
    return {
      inputVisible: false,
      inputValue: ""
    };
  },
  props: {
    tags: {
      required: true,
      type: String
    },
    autocomplete: {
      required: false,
      type: Boolean,
      default: false
    },
    autocompleteList: {
      required: false,
      type: Array
    }
  },
  computed: {
    getTagsArray() {
      return this.tags ? this.tags.split(",") : [];
    }
  },
  methods: {
    handleTagInputClose() {
      this.inputVisible = false;
    },
    handleDeleteTag(tag) {
      let tagsArray = _.split(this.tags, ",");
      const tagIndex = _.findIndex(tagsArray, tagA => {
        return tagA === tag;
      });
      tagsArray.splice(tagIndex, 1);
      this.$emit("tag-update", tagsArray.join(","));
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (this.tags) {
          this.$emit("tag-update", this.tags + "," + inputValue);
        } else {
          this.$emit("tag-update", this.tags + inputValue);
        }
        this.inputVisible = false;
        this.inputValue = "";
      }
    },
    querySearch(queryString, cb) {
      let suggestions = this.autocompleteList;
      let results = queryString
        ? suggestions.filter(this.createFilter(queryString))
        : suggestions;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return suggestion => {
        return (
          suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/tags.scss";
</style>
